<template>
    <v-sheet
      class="mb-3 pa-3 pb-4 text-center outlet-form sheet-form"
    >
      <h3>Update Name</h3>
      <v-form
        ref="formName"
        v-model="valid"
        lazy-validation
        class="mb-4 wallet-form"
        @submit="submit()"
      >
      <v-text-field
        v-model="update.name"
        label="Name"
        type="text"
        :loading="busy"
        :rules="[rules.required]"
        :error-messages="error && error.name ? error.name[0] : ''"
        @keypress.enter="submit('name')"
        required
      ></v-text-field>
      </v-form>
      <v-btn block color="primary" :loading="busy" @click="submit()">SUBMIT</v-btn>
    </v-sheet>
</template>

<script>
import data from './../mixins/data'
import rules from './../mixins/rules'

export default {
    mixins: [data, rules],
    data: () => ({
        busy: false,
        error: null,
        update: {
            name: null
        },
        valid: true
    }),
    computed: {
        user () {
            return this.$store.state.user
        }
    },
    methods: {
        submit() {
            if (this.$refs.formName.validate()) {
                this.postData(`/cfg/account`, this.update, data => {  
                    let user = data.data.data
                    localStorage.setItem('user', JSON.stringify(user))
                    this.$store.dispatch('setUser', user)
                    this.$store.dispatch("prompt", { message: 'Account name updated!' })
                }, null, 'PUT')
            }
        }
    },
    watch: {
        'user.name' () {
            this.update.name = this.user.name
        }
    },
    mounted () {
        this.update.name = this.user.name
    }
}
</script>